import { setCarousel } from "./scripts/rail";
import { callEndpoint, createPlaceholders } from "./scripts/update";
import { setRailClicks, getVideoEndState } from "./scripts/video";

export default function(rootElement) {
  if (!rootElement) {
    return;
  }

  const rail = rootElement.querySelector('[data-role="rail"]');
  const iframe = rootElement.closest('[data-role="parent"]')?.querySelector(`[id='${rootElement.dataset.iframeId}']`);
  const iframeUrl = rootElement.dataset.iframeUrl;
  const template = rootElement.querySelector('template').content.firstElementChild;
  const updateInterval = Number(rootElement.dataset.updateInterval || '10000');
  const maxItems = Number(rootElement.dataset.maxItems || '10');
  const endpoint = rootElement.dataset.endpoint;
  const settings = { muted: true };

  let items = rootElement.querySelectorAll('[data-role="rail-item"]');
  let stories = rootElement.querySelectorAll('[data-role="rail-story"]');

  setCarousel(rootElement);

  if (!endpoint || !iframe || !iframeUrl) {
    return;
  }

  createPlaceholders(rootElement, rail, template, maxItems, items, stories, settings);
  setRailClicks(rail, items, stories, iframe, iframeUrl, settings);
  getVideoEndState(items, stories, settings);
  callEndpoint(endpoint, rootElement, items, settings);

  rootElement.updateInterval = setInterval(() => {
    callEndpoint(endpoint, rootElement, items, settings);
  }, updateInterval);

}