export function setVideo({ id, title, item, iframe, url, autoplay = true, muted = true, disableAds = false }) {
  item.dataset.selected = true;
  window['requestIdleCallback' in window ? 'requestIdleCallback' : 'requestAnimationFrame'](() => {
    const search = new URLSearchParams({
      'auto-play': autoplay,
      muted
    });

    iframe.src = `${url}/${id}?${search}${disableAds === true ? '&disableAds=true' : ''}`;
    iframe.title = title;
  });
}

export function setRailClicks(rail, items, stories, iframe, url, settings) {
  rail.onclick = (event) => {
    event.preventDefault();
    if (event.target.matches('[data-role="rail-story"]')) {
      const story = event.target;
      const index = Array.from(stories).indexOf(story);
      items.forEach((item) => { item.removeAttribute('data-selected'); });
      settings.selectedItem = story.dataset.id;
      setVideo({
        id: story.dataset.id,
        title: story.dataset.title,
        item: items[index],
        iframe,
        url,
        muted: settings.muted,
        disableAds: (/live/i).test(story.dataset.type)
      });
    }
  };
};

export function getVideoEndState(items, stories, settings) {
  window.addEventListener('message', (event) => {
    if (event.data === 'brightcove-video-end' && items[0].dataset.selected !== 'true' && stories[0].dataset.type === 'live') {
      stories[0].click();
    }
    if (typeof event.data === 'string' && event.data.match(/brightcove-video-muted=/)) {
      settings.muted = event.data.split('=')[1] === 'true';
    }
  }, false);
}
