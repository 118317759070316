export const updateItem = (item, data) => {
  const story = item.querySelector('[data-role="rail-story"]');
  const label = item.querySelector('[data-role="label"]');
  const hiddenLabelText = label.querySelector('[data-role="label-hidden-text"]');

  setAttr(item.querySelector('img'), 'src', data.listingImage.template.replace('{width}x{height}', '384x216'));
  setAttr(item.querySelector('source'), 'srcset', data.listingImage.template.replace('{width}x{height}', '384x216'));
  setAttr(item.querySelector('img'), 'alt', data.listingImage.alt);
  setText(story, data.indexTitle);
  setAttr(story, 'data-title', data.indexTitle);
  setAttr(story, 'data-type', data.significance);
  setAttr(story, 'data-id', data.videoMediaItemId);
  setAttr(story, 'href', data.link);

  // set media label
  setAttr(label, 'data-type', data.mediaLabel.type);
  setText(hiddenLabelText, data.mediaLabel['a11y-text']);

  label.querySelectorAll('span[aria-hidden]').forEach((span) => {
    span.parentNode.removeChild(span);
  });

  data.mediaLabel.text.forEach((text) => {
    label.insertAdjacentHTML('beforeend', `<span aria-hidden="true">${text}</span>`);
  });
};

export const update = (data, items, rootElement, settings) => {
  if (!data) {
    console.error('No data');
  }

  setParentVisibility(data, rootElement);
  rootElement.dataset.updating = true;
  rootElement.style.setProperty('--items', data.length);

  items.forEach((item, index) => {
    if (data[index]) {
      updateItem(item, data[index]);
      item.dataset.selected = Number(data[index].videoMediaItemId) === Number(settings.selectedItem);
      item.style.display = 'block';
    } else {
      item.style.display = 'none';
    }
  });
  setTimeout(() => {
    rootElement.dataset.updating = false;
  }, 1000);
};

export const setText = (el, value) => {
  if (!el || !value) {
    return;
  }

  if (el.textContent.trim() !== value.trim()) {
    el.textContent = value;
  }
};

export const setAttr = (el, attr, value) => {
  if (!el || !attr || !value) {
    return;
  }

  if (el.getAttribute(attr) !== value) {
    el.setAttribute(attr, value);
  }
};

export const setParentVisibility = (data, rootElement) => {
  const parent = rootElement.closest('[data-role="parent"]');
  if (parent) {
    parent.dataset.railState = Math.min(data.length, 2);
  }
};

export function createPlaceholders(rootElement, rail, template, maxItems, items, stories, settings){
  Array.from(Array(maxItems)).forEach((item, index) => {
    if (!items[index]) {
      const newItem = template.cloneNode(true);
      newItem.style.display = 'none';
      rail.appendChild(newItem);
    }
  });

  items = rootElement.querySelectorAll('[data-role="rail-item"]');
  stories = rootElement.querySelectorAll('[data-role="rail-story"]');
  settings.selectedItem = stories[0].dataset.id;
}

export function callEndpoint(endpoint, rootElement, items, settings) {
  fetch(endpoint).then((response) => response.json()).then((json) => {
    if (json.checksum !== rootElement.dataset.checksum && json.timestamp > Number(rootElement.dataset.timestamp)) {
      if (json.articles && json.articles.length === 0) {
        // stop polling if the basket is empty
        clearInterval(rootElement.updateInterval);
      }

      update(json.articles, items, rootElement, settings);
      rootElement.dataset.timestamp = json.timestamp;
      rootElement.dataset.checksum = json.checksum;
    }
  }).catch((error) => {
    console.error(error);
  });
}
