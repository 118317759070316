export function setNav(rootElement, nav, back, forward) {
  if (rootElement.scrollWidth - rootElement.offsetWidth === 0) {
    back = false;
    forward = false;
  };

  nav[0].dataset.show = back;
  nav[0].disabled = !back;
  nav[1].dataset.show = forward;
  nav[1].disabled = !forward;
}

/* Rail can't calculate the scroll state until it is visible in the dom */
export const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.intersectionRatio > 0) {
      entry.target.dispatchEvent(new CustomEvent('scroll'));
      observer.unobserve(entry.target);
    };
  });
}, {})

export function setCarousel(rootElement) {

  observer.observe(rootElement);

  const nav = rootElement.querySelectorAll(".ui-carousel-rail-nav");
  const items = rootElement.querySelectorAll(".ui-carousel-rail-item");
  if (items.length < 2) {
    return;
  }

  rootElement.addEventListener("scroll", (event) => {
    window.requestAnimationFrame(() => {
      const left = 0;
      const right = event.target.scrollWidth - event.target.offsetWidth;
      const current = event.target.scrollLeft;
      setNav(rootElement, nav, current > (left + 10), current < (right - 10), 2);
    })
  },
    { passive: true });

  /* set nav click actions */
  nav.forEach((item, index) => {
    item.onclick = () => {
      const dirs = [-1, 1];
      const itemWidth = items[0].offsetLeft + items[1].offsetLeft;
      const itemsVisible = Math.floor(rootElement.offsetWidth / itemWidth);
      const scrollAmount = itemWidth * itemsVisible;
      rootElement.scrollBy(scrollAmount * dirs[index], 0);
    };
  });

  /* set initial nav state */
  setNav(rootElement, nav, false, true);
}